'use client'

import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';
import { SDKWrapper } from '@/lib/helpers/directus/SDKWrapper';
import Loading from '@/components/Loading';
import AdminDashboard from '@/components/dynamicPages/admin/Dashboard';
import UserDashboard from '@/components/dynamicPages/user/Dashboard';

import Bugsnag from '@bugsnag/js';

export default function Page() {
    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);
    const sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUser() {
            const userData = await sdkWrapper.getUserData();

            if (userData) {
                setUser(userData);
            }
        }

        async function fetchData() {
            try {
                await fetchUser();
                setLoading(false);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error(error);
                setLoading(false);
            }
        }

        if (localStorageObject.isLoggedIn) {
            fetchData();
        }
    }, []);

    if (localStorageObject.loading || loading) {
        return (
            <div className="h-full flex">
                <Loading />
            </div>
        )
    } else {
        return (
            user ? (
                <div>
                    {(user.role.id === process.env.NEXT_APP_DIRECTUS_MANAGER_ROLE || user.role.id === process.env.NEXT_APP_DIRECTUS_ADMIN_ROLE || user.role === process.env.NEXT_APP_DIRECTUS_MANAGER_ROLE || user.role === process.env.NEXT_APP_DIRECTUS_ADMIN_ROLE) ? (
                        <AdminDashboard user={user} />
                    ) : (
                        (user.role.id === process.env.NEXT_APP_DIRECTUS_USER_ROLE || user.role === process.env.NEXT_APP_DIRECTUS_USER_ROLE) && (
                            <UserDashboard user={user} />
                        )
                    )}
                </div>
            ) : (
                    <>
                    Nothing here</>
            )
        )
    }
}
