'use client'

import React, { useEffect, useState } from 'react'
import StackedCards from '@/components/global/StackedCards';
import { useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';
import { GameWrapper } from '@/lib/helpers/GameWrapper';
import Loading from '@/components/Loading';

export default function UserDashboard({ user }) {
    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);
    const gameWrapper = new GameWrapper(1, user, localStorageObject, setLocalStorageObject);

    const [loading, setLoading] = useState(true);

    const [levelData, setLevelData] = useState(null);

    useEffect(() => {
        async function fetchGames() {
            const game = await gameWrapper.getGame(true);

            setLevelData(game);

            setLoading(false);
        }

        if (localStorageObject.isLoggedIn) {
            fetchGames();
        }
    }, [localStorageObject.isLoggedIn]);

	return (
		<>
            {loading ? (
                <div className='flex' style={{height: '100dvh'}}>
                    <Loading />
                </div>
            ) : (
                <>
                    <div className="my-10">
                        {user && (
                            <>
                                <h4>Hallo {user.first_name ?? ''} {user.last_name ?? ''}!</h4>
                                <p>De Wijnles</p>
                            </>
                        )}
                    </div>

                    {(levelData && levelData[0].subCards.length > 0) ? (
                        <>
                            <StackedCards cards={levelData} />
                        </>
                    ) : (
                        <div className="my-10">
                            <p>Je hebt nog geen licenties.</p>
                        </div>
                    )}
                </>
            )}
        </>
	)
}
